import React, { useState } from "react";
import "./css/form1.css";

const ReportIncidentForm = ({ onClose }) => {
  // State variables to store form data
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [description, setDescription] = useState("");
  const [priority, setPriority] = useState("P1"); // Default priority

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Process the form data as needed (e.g., send it to a server)
    // You can access the form data using the state variables (name, email, phone, etc.)
    console.log("Form submitted:", { name, email, phone, company, description, priority });
    onClose();
  };

  return (
    <div className="form-popup">
      <div className="form1-head">
      <p className="form1-text1">Report Incident Form</p>
      <div className="close-button" onClick={onClose}>
        <span>&times;</span>
      </div>
      </div>
      <form onSubmit={handleSubmit} className="form1">
        <input
        label="Name"
          placeholder="Name"
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />

        <input
          placeholder="Email"
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <input
          placeholder="Phone Number"
          type="tel"
          id="phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />

        <input
          placeholder="Company Name"
          type="text"
          id="company"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
          required
        />

        <textarea
        placeholder="Description"
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        ></textarea>

        <label htmlFor="priority">Level of Priority</label>
        <select
          id="priority"
          value={priority}
          onChange={(e) => setPriority(e.target.value)}
          required
        >
          <option value="P1">P1</option>
          <option value="P2">P2</option>
          <option value="P3">P3</option>
          <option value="P4">P4</option>
        </select>

        <button type="submit" className="form1-buttom">Report Incident</button>
      </form>
    </div>
  );
};

export default ReportIncidentForm;
