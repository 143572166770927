import React, { Suspense } from "react";
import "./App.css";
import CompanyPage from "./components/CompanyPage";
// import HomePage from './components/HomePage';
// import ErrorBoundary from "./components/ErrorBoundary";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "../src/components/css/loader.css";
import { ErrorBoundary } from "react-error-boundary";
import Erroricon from "../src/assets/images/Erroricon.svg";
import "../src/components/Error.css"
import { IoReload } from "react-icons/io5";

// lazy loading components
const HomePage = React.lazy(() => import("./components/HomePage"));
const ProductPage = React.lazy(() => import("./components/ProductPage"));
const RedTeamPage = React.lazy(() => import("./components/RedTeamPage"));
const ResourcesPage = React.lazy(() => import("./components/ResourcesPage"));
const CaseStudy = React.lazy(() => import("./components/CaseStudy"));
function App() {
  return (
    <Suspense
      fallback={
        <div className="loader-container">
          <span class="loader"></span>
        </div>
      }
    >
      <ErrorBoundary
        fallback={
          <section
          className="fallback-ui"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              width: "100vw",
              backgroundColor:"white"
            }}
          >
            <article
              style={{
                // backgroundColor: "white",
                display: "flex",
                gap: "1rem",
                justifyContent:"center",
                alignItems:"center",
                flexDirection: "column",
              }}
            >
              <div
                className="error-text"
               
              >
                Error
              </div>
              <div className="erro-desc">Something Went Wrong!</div>
              <img src={Erroricon} width={"200rem"}></img>
              <button className="error-retry-btn" onClick={()=> window.location.href="/"} style={{color:"white"}}><IoReload className="reload-icon"/><span>Retry</span></button>
            </article>
          </section>
        }
      >
        <Router>
          <Routes>
            <Route  path="/" element={<HomePage />} />
        
            <Route path="/company" element={<CompanyPage />} />
        
                     <Route path="/product" element={<ProductPage />} />
         
          
            <Route path="/resources" element={<ResourcesPage />} />
          
          
            <Route path="/redteam" element={<RedTeamPage />} />
           
          
            <Route path="/caseStudy" element={<CaseStudy />} />
          
            <Route path="/caseStudy/:id" element={<CaseStudy />} />
          
            <Route path="*" element={ <section
          className="fallback-ui"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              width: "100vw",
              backgroundColor:"white"
            }}
          >
            <article
              style={{
                // backgroundColor: "white",
                display: "flex",
                gap: "1rem",
                justifyContent:"center",
                alignItems:"center",
                flexDirection: "column",
              }}
            >
              <div
                className="error-text"
               
              >
                Error 404
              </div>
              <div className="erro-desc">Page Not Found!</div>
              <img src={Erroricon} width={"200rem"}></img>
              <button className="error-retry-btn" onClick={()=> window.location.href="/"} style={{color:"white"}}><IoReload className="reload-icon"/><span>Retry</span></button>
            </article>
          </section>} />
          </Routes>
        </Router>
      </ErrorBoundary>
    </Suspense>

  );
}

export default App;
