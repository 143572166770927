import React, { useState, useEffect } from "react";
import "./company_widget3.css";

import img1 from '../../assets/images/CW31.png';
import img2 from '../../assets/images/CW32.png';
import img3 from '../../assets/images/CW33.png';

function CompanyWidget3() {
 
  const cards = [
    { img: img1, text: "Tons of Indoor Games to Relax" },
    { img: img2, text: "Small Gaming Sessions" },
    { img: img3, text: "Work from Anywhere Opportunities" }
  ];

  const totalCards = cards.length;
  const [activeCardIndex, setActiveCardIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveCardIndex((prevIndex) => (prevIndex + 1) % totalCards);
    }, 5000); // Change the interval as needed (milliseconds)

    return () => clearInterval(interval);
  }, []);

  const handleDotClick = (index) => {
    setActiveCardIndex(index);
  };

 return (
    <div className="company-widget3">
      <p className="cw2-t1">Life at Codesnag</p>
      <div className="cw3-card-container">
        
        <div className="cw3-moving-cont">
          {cards.map((card, index) => (
            <div
              key={index}
              className={`cw3-card ${index === activeCardIndex ? 'active' : ''}`}
              style={{
                transform: `translateX(-${activeCardIndex * 100}%)`,
                opacity: index === activeCardIndex ? 1 : 0
              }}
            ><div className="cw3-black-overlay">
              <img src={card.img} alt={`img${index + 1}`} className="cw2-card-img" />
              <p className="cw3-t2">{card.text}</p></div>
            </div>
          ))}
        </div>
        {/* Dot indicators */}
        <div className="dot-indicator">
          {Array.from({ length: totalCards }).map((_, index) => (
            <div
              key={index}
              className={`dot ${index === activeCardIndex ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </div>
        
      </div>
    </div>
  );
}

export default CompanyWidget3;