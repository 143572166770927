import React, { useState, useEffect } from "react";
import "./home_footer.css";
import logo from '../../assets/images/WhiteLogo.svg';

import fb from '../../assets/images/Fb.svg';
import insta from '../../assets/images/Instagram.svg';
import linkdin from '../../assets/images/Linkedin.svg';
import twitter from '../../assets/images/X.svg';

import telephone from '../../assets/images/Telephone.svg';
import location from '../../assets/images/Location.svg';
import man from '../../assets/images/FooterSubmitMan.svg';
import ReportIncidentForm from "../ReportIncidentForm";
import ScheduleDemoForm from '../ScheduleDemoForm';
function HomeFooter({ activeMenu }) {
  const [showReportForm, setShowReportForm] = useState(false);
  const [showDemoForm, setShowDemoForm] = useState(false);

  const handleOpenForm = (e) => {
    e.preventDefault();
    setShowReportForm(true);
  }

  const handleCloseForm = () => {
    setShowReportForm(false);
  }
  const handleOpenDemoForm = (e) => {
    e.preventDefault();
    setShowDemoForm(true);
  }

  const handleCloseDemoForm = () => {
    setShowDemoForm(false);
  }
  // const [formData, setFormData] = useState({
  //   name: '',
  //   email: '',
  //   phoneNumber: '',
  // });

  // const [formErrors, setFormErrors] = useState({
  //   name: '',
  //   email: '',
  //   phoneNumber: '',
  // });

  // const [showPopup, setShowPopup] = useState(false);

  const handleNavigate = (type) => {
    const socialMediaLinks = {
      facebook: 'https://www.facebook.com/CodeSnag/',
      twitter: 'https://twitter.com/codesnag',
      instagram: 'https://www.instagram.com/codesnag/',
      linkedin: 'https://www.linkedin.com/company/codesnag/',
    };

    const url = socialMediaLinks[type];

    if (url) {
      window.open(url, '_blank'); 
      // window.location.href = url;
    }
  };

  // // Event handler to update form state on input change
  // const handleChange = (e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   });
  //   setFormErrors({
  //     ...formErrors,
  //     [e.target.name]: '',
  //   });
  // };

  // const validateForm = () => {
  //   let isValid = true;

  //   // Validate name
  //   if (formData.name.trim() === '') {
  //     setFormErrors((prevErrors) => ({
  //       ...prevErrors,
  //       name: 'Name cannot be empty',
  //     }));
  //     isValid = false;
  //   }

  //   // Validate email
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   if (!emailRegex.test(formData.email)) {
  //     setFormErrors((prevErrors) => ({
  //       ...prevErrors,
  //       email: 'Enter a valid email address',
  //     }));
  //     isValid = false;
  //   }

  //   // Validate phone number
  //   const phoneRegex = /^\d{10,}$/;
  //   if (!phoneRegex.test(formData.phoneNumber.replace(/\D/g, ''))) {
  //     setFormErrors((prevErrors) => ({
  //       ...prevErrors,
  //       phoneNumber: 'Enter a valid phone number',
  //     }));
  //     isValid = false;
  //   }

  //   return isValid;
  // };

  // // Event handler for form submission
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (validateForm()) {      
  //   console.log('Form submitted:', formData);

  //   // Clear the form data
  //   setFormData({
  //     name: '',
  //     email: '',
  //     phoneNumber: '',
  //   });

  //   // Show the popup
  //   setShowPopup(true);

  //   // Hide the popup after 10 seconds
  //   setTimeout(() => {
  //     setShowPopup(false);
  //   }, 5000);
  // }
  // };

  // useEffect(() => {
  //   // Clear the form data when the component mounts
  //   setFormData({
  //     name: '',
  //     email: '',
  //     phoneNumber: '',
  //   });
  // }, []);

  return (
    <div className={activeMenu==="Red Team" ? "homefooter2": "homefooter"}>
      {/* {showPopup && (
        <div className="footer-popup">
          <p>Your request has been reached to us,<span className="footer-popup-span">Our team will reach out to you soon!</span></p>
          <img className="footer-popup-img" src={man} alt='img'/>
        </div>
      )} */}
       {showReportForm && (
        <ReportIncidentForm onClose={handleCloseForm} />
      )}
      {showDemoForm && (
        <ScheduleDemoForm onClose={handleCloseDemoForm} />
      )}
      <div className="footer-top">
        <div className="foot-sec1">
          <img alt="logo" className="logo-foot" src={logo}/>
          <div className={activeMenu === 'Red Team'?"foot1-button-red":"foot1-button-blue"} onClick={handleOpenDemoForm}>Schedule a Demo</div>
        <div className={activeMenu === 'Red Team'?"foot1-button-red":"foot1-button-blue"} onClick={handleOpenForm}>Report Incident</div>

          {/* <p className="foot-text1">Connect With Us</p>
          <p className="foot-text2">Fill up the form and our team will get back to you</p>
          <form className="foot-form">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Name"
            className="foot-name"
        />
      <br />
      {formErrors.name && <p className="error-message">{formErrors.name}</p>}

      <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email Address"
          className="foot-email"
        />
      <br />
      {formErrors.email && <p className="error-message">{formErrors.email}</p>}

      <input
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          placeholder="Phone No."
          className="foot-phone"
        />
      <br />
      {formErrors.phoneNumber && <p className="error-message">{formErrors.phoneNumber}</p>}

      <div type="submit" onClick={handleSubmit} className={activeMenu==="Red Team" ? "foot-button2":"foot-button"}>Submit</div>
    </form> */}
        </div>
        {/* <div className="foot-sec2">
          <p className="foot-text1">Company</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
        </div>
        <div className="foot-sec3">
          <p className="foot-text1">Resources</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
        </div>
        <div className="foot-sec4">
          <p className="foot-text1">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
        </div> */}
        <div className="foot-sec5">
          <p className="foot-text1 text-right">Stay in Touch</p>
          <div className="foot-location">
          <p className="foot-text3">+91 9310-498-787</p>
          <img alt="telephone" className="telephone" src={telephone}/>
          </div>
          <div className="foot-telephone">
          <p className="foot-text3">A-8, Block B, Acharya Niketan, Mayur Vihar, Delhi, 110091</p>
          <img alt="address" className="address" src={location}/>
          </div>
          <p className="foot-text1 text-right">Connect with us</p>
          <p className="social-logo">
            <img className="fb" alt="fb" src={fb} onClick={() => handleNavigate('facebook')}/>
            <img className="twitter" alt="tw" src={twitter} onClick={() => handleNavigate('twitter')}/>
            <img className="insta" alt="insta" src={insta} onClick={() => handleNavigate('instagram')}/>
            <img className="likdin" alt="linkdin" src={linkdin} onClick={() => handleNavigate('linkedin')}/>
          </p>
        </div>

      </div>
      <div className="footer-top-small">
      <div className="foot-sec5">
      <img alt="logo" className="logo-foot" src={logo}/>
      <p className="foot-text1 text-right">Connect with us</p>
          <p className="social-logo">
          <img className="fb" alt="fb" src={fb} onClick={() => handleNavigate('facebook')}/>
            <img className="twitter" alt="tw" src={twitter} onClick={() => handleNavigate('twitter')}/>
            <img className="insta" alt="insta" src={insta} onClick={() => handleNavigate('instagram')}/>
            <img className="likdin" alt="linkdin" src={linkdin} onClick={() => handleNavigate('linkedin')}/>
          </p>
          <p className="foot-text1 text-right">Stay in Touch</p>
          <div className="foot-location">
          <p className="foot-text3">+91 9310-498-787</p>
          <img alt="telephone" className="telephone" src={telephone}/>
          </div>
          <div className="foot-telephone">
          <p className="foot-text3">A-8, Block B, Acharya Niketan, Mayur Vihar, Delhi, 110091</p>
          <img alt="address" className="address" src={location}/>
          </div>
          
        </div>
        {/* <div className="foot-sec2">
          <p className="foot-text1">Company</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
        </div>
        <div className="foot-sec3">
          <p className="foot-text1">Resources</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
        </div>
        <div className="foot-sec4">
          <p className="foot-text1">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
          <p className="foot-text3">Lorem Ipsum</p>
        </div> */}
        
        <div className="foot-sec1">
        <div className={activeMenu === 'Red Team'?"foot1-button-red":"foot1-button-blue"} onClick={handleOpenDemoForm}>Schedule a Demo</div>
        <div className={activeMenu === 'Red Team'?"foot1-button-red":"foot1-button-blue"} onClick={handleOpenForm}>Report Incident</div>

          {/* <p className="foot-text1">Connect With Us</p>
          <p className="foot-text2">Fill up the form and our team will get back to you</p>
          <form  className="foot-form">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Name"
            className="foot-name"
        />
      <br />
      {formErrors.name && <p className="error-message">{formErrors.name}</p>}

      <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email Address"
          className="foot-email"
        />
      <br />
      {formErrors.email && <p className="error-message">{formErrors.email}</p>}

      <input
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          placeholder="Phone No."
          className="foot-phone"
        />
      <br />
      {formErrors.phoneNumber && <p className="error-message">{formErrors.phoneNumber}</p>}

      <div type="submit" onClick={handleSubmit} className={activeMenu==="Red Team" ? "foot-button2":"foot-button"}>Submit</div>
    </form> */}
        </div>

      </div>
      <div className={activeMenu==="Red Team" ? "foot2" :"foot"}>
        <div className="foot-left">
            <p className="copywrite">@2023 codesnag</p>
        </div>
        <div className="foot-right">
            <p className="text-term">Private Policy</p>
            <p className="text-term">Terms and Conditions</p>
        </div>
      </div>
    </div>
  );
}

export default HomeFooter;
