import React, { useState } from "react";
import "./css/form1.css";

const ScheduleDemoForm = ({ onClose }) => {
  // State variables to store form data
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", { name, email, phone, company });
    onClose();
  };

  return (
    <div className="form-popup">
      <div className="form1-head">
      <p className="form1-text1">Schedule a Demo</p>
      <div className="close-button" onClick={onClose}>
        <span>&times;</span>
      </div>
      </div>
      <form onSubmit={handleSubmit} className="form1">
        <input
        label="Name"
          placeholder="Name"
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />

        <input
          placeholder="Email"
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <input
          placeholder="Phone Number"
          type="tel"
          id="phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />

        <input
          placeholder="Company Name"
          type="text"
          id="company"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
          required
        />


        <button type="submit" className="form1-buttom">Schedule a Demo</button>
      </form>
    </div>
  );
};

export default ScheduleDemoForm;
